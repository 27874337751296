import "../styles/chat.scss";
import React, { useState, useEffect, useRef } from "react";
import send_message_icon from "../assets/send_comment.png";
import upload_image_icon from "../assets/upload_image.png";
import options_icon from "../assets/options.png";
import cancel_icon from "../assets/close.png";
import upload_icon from "../assets/upload.png";
import { SetPopup, defaultTryCatch } from "../utils/popup";
import { getActiveUser, getToken, getServerIP } from "../utils/util.js";
import { getChat, updateChat, loadChat } from "../utils/data.chat.js";
import DateSeparator from "./DateSeparator";

const SERVER_IP = getServerIP();
const MAX_TEXT_LENGTH = 3500;
let waitingForResponse = false;

let previous = 0;
const setPrevious = (num) => (previous = num);
const getPrevious = () => previous;

export default function Profiles() {
  const contentRef = useRef();
  const sendRef = useRef();

  const previewRef = useRef();
  const mediaRef = useRef();

  const [activeUser, setActiveUser] = useState(getActiveUser().value);
  const [data, setData] = useState(undefined);

  // need reset on case user changes the screen and comes back
  previous = 0;

  useEffect(() => {
    async function scrollListener(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      if (scrollTop + offsetHeight >= scrollHeight) {
        document
          .getElementsByClassName("container")[0]
          .removeEventListener("scroll", scrollListener);

        if (waitingForResponse) return;
        waitingForResponse = true;
        await loadChat();
        setData(getChat());
        waitingForResponse = false;
      }
    }

    // fill to height
    if (data && !data.length) return;
    let container = document.getElementsByClassName("container")[0];
    const { scrollTop, offsetHeight, scrollHeight } = container;
    if (scrollTop + offsetHeight >= scrollHeight) {
      if (!data || waitingForResponse) return;
      waitingForResponse = true;
      scrollListener({ target: container });
      waitingForResponse = false;
    }

    document
      .getElementsByClassName("container")[0]
      .addEventListener("scroll", scrollListener);

    return () => {
      document
        .getElementsByClassName("container")[0]
        .removeEventListener("scroll", scrollListener);
    };
  }, [data]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!data || waitingForResponse) return;
      waitingForResponse = true;
      await updateChat();
      setData(getChat());
      waitingForResponse = false;
    }, 1500);
    return () => clearInterval(interval);
  }, [data, activeUser]);

  useEffect(() => {
    const load = async () => {
      if (!data || !data.length) await loadChat();
      setData(getChat());
    };
    load();
  }, []);

  function closePreview() {
    mediaRef.current.value = null;
    previewRef.current.children["img-container"].children["img-element"].src =
      "";
    previewRef.current.style.display = "none";
    previewRef.current.style.visibility = "hiddend";
  }

  function handleInput() {
    contentRef.current.style.height = "auto";
    let lineHeight = parseInt(
      window.getComputedStyle(contentRef.current).lineHeight
    );
    let scrollHeight = contentRef.current.scrollHeight;

    let height =
      scrollHeight / lineHeight >= 4
        ? 4 * 20
        : parseInt(scrollHeight / lineHeight) * 20;
    contentRef.current.style.height = height + "px";
  }

  async function sendMessage() {
    if (!contentRef.current.value) return;
    if (!contentRef.current.value.replace(/\s/g, "").length) return;
    if (!contentRef.current.textLength > MAX_TEXT_LENGTH) return;

    const token = await getToken();
    const message = {
      username: activeUser,
      content: contentRef.current.value,
      timestamp: new Date().getTime(),
    };

    contentRef.current.value = "";
    handleInput(); //reset height

    await fetch(`${SERVER_IP}/api/addmessage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          token: token.value,
          message,
        },
      ]),
    }).then(async function (res) {
      if (!res.ok) {
        console.log(res);
        SetPopup({
          name: res.status,
          content: "/api/addmessage endpoint not found",
        });
        return;
      }
      await res.json().then(async function (res) {
        if (res.status === "error") {
          SetPopup({ name: res.status, content: "Could not send message" });
        }
      });
    });
  }

  async function previewMedia() {
    if (!mediaRef.current.files) return;
    var reader = new FileReader();

    reader.onload = function (e) {
      previewRef.current.children["img-container"].children["img-element"].src =
        e.target.result;
      previewRef.current.style.display = "grid";
      previewRef.current.style.visibility = "visible";
    };

    reader.readAsDataURL(mediaRef.current.files[0]);
  }

  async function sendMedia() {
    if (!mediaRef.current.files) return;
    if (!mediaRef.current.files[0].type.startsWith("image/")) {
      SetPopup({
        name: "Invalid type",
        content: `${mediaRef.current.files[0].type}`,
      });
      return;
    }
    if (mediaRef.current.files[0].size >= 15 * 1024 * 1024) {
      const size =
        Math.floor((mediaRef.current.files[0].size / 1024 / 1024) * 100) / 100;
      SetPopup({
        name: `Too large: ${size}MB`,
        content: `Maximum allowed size is 15MB`,
      });
      return;
    }

    const token = await getToken();
    const formData = new FormData();
    formData.append("file", mediaRef.current.files[0]);
    formData.append("token", token.value);
    formData.append("username", activeUser);
    formData.append("timestamp", new Date().getTime());

    closePreview();

    await fetch(`${SERVER_IP}/api/addmedia`, {
      method: "POST",
      body: formData,
    }).then(async function (res) {
      if (!res.ok) {
        SetPopup({
          name: res.status,
          content: "/api/addmedia endpoint not found",
        });
        return;
      }
      await res.json().then(async function (res) {
        if (res.status === "error") {
          SetPopup({ name: res.status, content: "Could not upload photo" });
        }
      });
    });
  }

  return (
    <>
      <div className="chat">
        <div className="input">
          <button
            className="button-option"
            type="button"
            onClick={async () => {
              SetPopup({
                name: "Not Implemented",
                content: "More options will be added soon",
              });
            }}
          >
            <img src={options_icon} alt="o" />
          </button>
          <div className="message">
            <textarea
              maxLength={MAX_TEXT_LENGTH}
              ref={contentRef}
              className="content"
              placeholder="Send a new message..."
              onInput={handleInput}
              rows={1}
            ></textarea>
          </div>

          <div className="buttons">
            <button
              type="button"
              className="photo-upload-button"
              onClick={async () => {
                mediaRef.current.click();
              }}
            >
              <input
                hidden
                ref={mediaRef}
                type="file"
                accept="image/*"
                multiple
                onChange={async () => {
                  await defaultTryCatch(previewMedia, "Image send error");
                }}
              />
              <img src={upload_image_icon} alt="u" />
            </button>

            <button
              ref={sendRef}
              className="text-upload-button"
              type="button"
              onClick={async () => {
                await defaultTryCatch(sendMessage, "Message send error");
              }}
            >
              <img src={send_message_icon} alt="m" />
            </button>
          </div>
        </div>
        {data?.map((msg, key) => {
          return (
            <React.Fragment key={key}>
              <DateSeparator
                timestamp={msg.timestamp}
                getPrevious={getPrevious}
                setPrevious={setPrevious}
              />
              <MessageBlock msg={msg} activeUser={activeUser} />
            </React.Fragment>
          );
        })}
      </div>

      <div ref={previewRef} className="image-preview">
        <div id="img-container">
          <img id="img-element"></img>
        </div>
        <div className="buttons">
          <button>
            <img
              src={cancel_icon}
              alt="x"
              onClick={() => {
                closePreview();
              }}
            />
          </button>
          <button>
            <img
              src={upload_icon}
              alt="u"
              onClick={async () => {
                await defaultTryCatch(sendMedia, "Image send error");
              }}
            />
          </button>
        </div>
      </div>
    </>
  );
}

const MessageBlock = ({ msg, activeUser }) => {
  const time = msg.timestamp;
  const minutes = new Date(time).getMinutes();
  const hours = new Date(time).getHours();

  const isSelf = () => {
    if (msg.username == activeUser) return "self";
    else return "";
  };

  function isOnlyEmoji(text, length) {
    if (!text) return "";
    let emojis = text.match(/\p{Extended_Pictographic}/gu);
    if (emojis)
      for (const emoji of emojis) {
        text = text.replace(emoji, "");
      }
    if (!text.length && emojis?.length <= length) return "emoji";
    else return "";
  }

  return (
    <div className={`${isSelf()} message-block`}>
      <div className="header">
        <div className="wrapper">
          <p className="timestamp">
            {hours}:{minutes < 10 ? "0" + minutes : minutes}
          </p>
        </div>
      </div>
      <div className={`body ${msg.type} ${isOnlyEmoji(msg.content, 5)}`}>
        <p className="author">{msg.username}</p>
        <div className="content">
          {msg.type === "text" ? <p className="text">{msg.content}</p> : <></>}
          {msg.type === "image" ? (
            <img src={`${SERVER_IP}/uploads/media/${msg.path}`} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
