import "../styles/ConsumptionBlock.scss";
import { useEffect, useRef, useState } from "react";
import comment_icon from "../assets/comment.png";
import Comments from "./Comments";
import { getComments } from "../utils/data.comments";

function throttle(cb, delay) {
  let shouldWait = false;
  let waitingArgs;
  const timeoutFunc = () => {
    if (waitingArgs == null) {
      shouldWait = false;
    } else {
      cb(...waitingArgs);
      waitingArgs = null;
      setTimeout(timeoutFunc, delay);
    }
  };

  return (...args) => {
    if (shouldWait) {
      waitingArgs = args;
      return;
    }

    cb(...args);
    shouldWait = true;

    setTimeout(timeoutFunc, delay);
  };
}

export default function ConsumptionBlockSocial({ transaction, feed = false }) {
  const [fullRead, setFullRead] = useState(feed ? true : false);
  const [comments, setComments] = useState({
    visible: feed ? true : false,
    data: feed ? feed.comments : [],
  });
  const block = useRef(null);

  const throttleHandleTransition = throttle(async () => {
    let previous = block.current.style.height;
    block.current.style.transition = "none";
    block.current.style.height = "";
    let next = block.current.clientHeight + "px";

    block.current.style.height = previous;
    block.current.style.transition =
      "height 200ms cubic-bezier(0.85, 0.25, 0.6, 0.6)";

    // needed for transition to take effect & 50ms magically fixes first transition
    setTimeout(function () {
      block.current.style.height = next;
    }, 50);
  }, 100);

  useEffect(() => {
    throttleHandleTransition();

    window.addEventListener("resize", throttleHandleTransition);

    return (_) => {
      window.removeEventListener("resize", throttleHandleTransition);
    };
  }, [comments.visible, fullRead]);

  async function loadComments() {
    const res = await getComments(transaction._id);

    setComments((state) => {
      return {
        visible: !state.visible,
        data: res,
      };
    });
  }

  function read() {
    setFullRead((x) => !x);
  }

  return (
    <div
      ref={block}
      className={
        (fullRead ? "resized" : "") +
        " block " +
        transaction.color?.toLowerCase()
      }
    >
      <div className="basic static">
        <div className="data" onClick={read}>
          <div className="left">
            <h1>
              {feed ? <span>{transaction.username}:</span> : <></>}
              {transaction.alternative ? (
                <span>{transaction.alternative}</span>
              ) : (
                <>
                  <span>{transaction.color}</span>
                  <span>- {transaction.strain}</span>
                </>
              )}
            </h1>
            <p>{new Date(transaction.timestamp).toLocaleString()}</p>
          </div>
          <div className="right">
            <p>{transaction.amount}g</p>
          </div>
        </div>
        <div className="buttons">
          <button
            type="button"
            onClick={() => loadComments()}
            className={transaction.color?.toLowerCase()}
          >
            <img src={comment_icon} alt="c" />
          </button>
        </div>
        <div className="info" onClick={read}>
          <p>{transaction.info}</p>
        </div>
      </div>
      {comments.visible ? (
        <Comments transaction={transaction} comments={comments.data} />
      ) : (
        <></>
      )}
    </div>
  );
}
