import ConsumptionBlock from "../ConsumptionBlock";
import DateSeparator from "../DateSeparator";
import "../../styles/home.scss";
import React, { useEffect, useState } from "react";
import {
  getTransactions,
  loadTransactions,
  setTransactions,
  sortTransactions,
  getShortData,
} from "../../utils/transactions";
import { limitDecimal } from "../../utils/util";

let previous = 0;
const setPrevious = (num) => (previous = num);
const getPrevious = () => previous;

// TODO: fix the margin (add top) to message timestamp - its above the text (just remove the author.self padding)
// TODO: fix popup wrapping
// TODO: rewrite activeUser
// TODO: remove LOADED var from old algo, copy chat-algo to txn-algo for incremental loading
// TODO: investigate and fix dupe comments
// TODO: add loading screen (based on global dump from http requests)
// TODO: add reactions and replies (no need for this)
// TODO: do not show author if 2 messages are together (in a given time period)
// TODO: fix when updating transaction, the data.comments duplicate
// TODO: check how app acts when multiple "identical" txns are added

// TODO: on server rewrite !docs to !docs.length

export default function Home() {
  const [transactions, setTransactionsState] = useState(getTransactions());
  const consumption = getShortData();

  // incremental transaction load, fully renders from the top
  previous = 0;

  useEffect(() => {
    async function scrollListener(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      if (scrollTop + offsetHeight >= scrollHeight) {
        document
          .getElementsByClassName("container")[0]
          .removeEventListener("scroll", scrollListener);

        const loadedTransactions = await loadTransactions();
        if (!loadedTransactions.length) return;
        setTransactions(
          sortTransactions([...getTransactions(), ...loadedTransactions])
        );
        setTransactionsState(getTransactions());
      }
    }

    document
      .getElementsByClassName("container")[0]
      .addEventListener("scroll", scrollListener);

    return () => {
      document
        .getElementsByClassName("container")[0]
        .removeEventListener("scroll", scrollListener);
    };
  }, [transactions]);

  return (
    <div className="home">
      <div className="total">
        <h1>
          {limitDecimal(consumption.total, 100)}g of{" "}
          <span className="leaf">Leaf</span> ingested
        </h1>
        <div>
          <div>
            <p className="amount">{limitDecimal(consumption.day, 100)}g</p>
            <p className="name">Day</p>
          </div>
          <div>
            <p className="amount">{limitDecimal(consumption.week, 100)}g</p>
            <p className="name">Week</p>
          </div>
          <div>
            <p className="amount">{limitDecimal(consumption.month, 100)}g</p>
            <p className="name">Month</p>
          </div>
        </div>
      </div>
      <h2>
        <span className="leaf">Leaf</span> is life,{" "}
        <span className="leaf">Leaf</span> is love
      </h2>
      <div className="transactions">
        {transactions.map((transaction, key) => {
          return (
            <React.Fragment key={key}>
              <DateSeparator
                timestamp={transaction.timestamp}
                getPrevious={getPrevious}
                setPrevious={setPrevious}
              />
              <ConsumptionBlock
                transaction={transaction}
                setTransactionsState={setTransactionsState}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
