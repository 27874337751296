import React from "react";
import "../styles/profileblock.scss";
import { limitDecimal } from "../utils/util";

export default function ProfileBlock({ openProfile, data }) {
  return (
    <div className="profileblock" onClick={() => openProfile(data.username)}>
      <h1>
        {data?.username}
        <span>{data?.total ? limitDecimal(data.total, 10) : 0}g</span>
      </h1>
      <p>{data?.day ? limitDecimal(data.day, 10) : 0}gpd</p>
    </div>
  );
}
