import React, { useState, useEffect } from "react";
import { getData, loadFeed } from "../utils/data.social";
import { sortTransactions } from "../utils/transactions";
import ConsumptionBlockSocial from "./ConsumptionBlock.social";
import DateSeparator from "./DateSeparator";

let previous = 0;
const setPrevious = (num) => (previous = num);
const getPrevious = () => previous;

export default function Feed({}) {
  const [data, setDataState] = useState(getData().feed);

  // incremental transaction load, fully renders from the top
  previous = 0;

  useEffect(() => {
    async function scrollListener(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      if (scrollTop + offsetHeight >= scrollHeight) {
        document
          .getElementsByClassName("container")[0]
          .removeEventListener("scroll", scrollListener);

        const successLength = await loadFeed();
        if (!successLength) return;
        setDataState(sortTransactions(getData().feed));
      }
    }

    document
      .getElementsByClassName("container")[0]
      .addEventListener("scroll", scrollListener);

    return () => {
      document
        .getElementsByClassName("container")[0]
        .removeEventListener("scroll", scrollListener);
    };
  }, [data]);

  return (
    <div className="feed">
      {data?.map((txn, key) => {
        return (
          <React.Fragment key={key}>
            <DateSeparator
              timestamp={txn.timestamp}
              getPrevious={getPrevious}
              setPrevious={setPrevious}
            />
            <ConsumptionBlockSocial
              transaction={txn}
              feed={{ comments: txn.comments }}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}
