import dayjs from "dayjs";
import "../styles/dateseparator.scss";

const isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

export default function DateSeparator({ timestamp, getPrevious, setPrevious }) {
  if (dayjs().isSame(timestamp, "day")) return <></>;
  if (dayjs(getPrevious()).isSame(timestamp, "day")) return <></>;
  let year = false;
  if (!dayjs().isSame(timestamp, "year")) year = true;
  setPrevious(timestamp);

  return (
    <div className="dateseparator">
      <p>{dayjs(timestamp).format(`MMMM D${year ? ", YYYY" : ""}`)}</p>
    </div>
  );
}
