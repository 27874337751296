import "./styles/global.scss";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";

import Add from "./components/routes/Add";
import Home from "./components/routes/Home";
import Social from "./components/routes/Social";
import Layout from "./components/Layout";
import { initPopup } from "./utils/popup";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
initPopup();
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="add" element={<Add />} />
        <Route path="home" element={<Home />} />
        <Route path="social" element={<Social />} />
      </Route>
      <Route
        path="social/profile"
        element={
          <div className="App">
            <Layout>
              <Social />
            </Layout>
          </div>
        }
      />
    </Routes>
  </BrowserRouter>
);
