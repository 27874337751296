import { useEffect, useRef } from "react";
import "../styles/Comments.scss";
import { getActiveUser, getServerIP, getToken } from "../utils/util";
import send_comment_icon from "../assets/send_comment.png";
import { SetPopup } from "../utils/popup";
import { addComment as addCommentToData } from "../utils/data.comments";

const SERVER_IP = getServerIP();

export default function Comments({ comments, transaction }) {
  const username = useRef();
  const commentRef = useRef();
  const addRef = useRef();
  const commentsParent = useRef();

  function handleEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      addRef.current.click();
    }
  }

  async function addComment() {
    if (!commentRef.current.value) return;
    if (!commentRef.current.value.replaceAll(" ", "").length) return;
    const user = (await getActiveUser()).value;
    const token = await getToken();
    const data = {
      username: user,
      content: commentRef.current.value,
      txnid: transaction._id,
      timestamp: new Date().getTime(),
    };
    commentRef.current.value = "";
    await fetch(`${SERVER_IP}/api/addcomment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          token: token.value,
          comment: data,
        },
      ]),
    }).then(async function (res) {
      if (!res.ok) {
        console.error(res.ok);
        return;
      }
      await res.json().then(async function (res) {
        if (res.status === "error") {
          SetPopup({ name: res.status, content: "Could not comment" });
        }
      });
    });

    let newComment = document.createElement("div");
    newComment.classList.add("comment");

    let pUsername = document.createElement("p");
    pUsername.classList.add("username");
    pUsername.innerText = data.username;
    newComment.appendChild(pUsername);

    let pContent = document.createElement("p");
    pContent.classList.add("content");
    pContent.innerText = data.content;
    newComment.appendChild(pContent);

    commentsParent.current.insertBefore(
      newComment,
      commentsParent.current.lastChild
    );
    window.dispatchEvent(new Event("resize")); // force animation

    addCommentToData(data);
  }

  useEffect(() => {
    async function load() {
      const user = await getActiveUser();
      username.current.innerText = user.value;
    }
    load();
  }, []);

  return (
    <div ref={commentsParent} className="comments">
      {comments.map((comment, key) => {
        return (
          <div key={key} className="comment">
            <p className="username">{comment.username}</p>
            <p className="content">{comment.content}</p>
          </div>
        );
      })}
      <div className="new">
        <div className="comment new">
          <p className="username" ref={username}></p>
          <input
            ref={commentRef}
            onKeyDown={handleEnter}
            className="content"
            type="text"
            placeholder="Add a new comment..."
            maxLength={500}
            autoComplete="off"
            required
          />
        </div>
        <div className="buttons">
          <button
            ref={addRef}
            type="button"
            onClick={async () => {
              try {
                await addComment();
              } catch (err) {
                SetPopup({
                  name: "Comment add error",
                  content: `${err.name}: ${err.message}`,
                });
              }
            }}
          >
            <img src={send_comment_icon} alt="c" />
          </button>
        </div>
      </div>
    </div>
  );
}
