import { useState, useEffect, useRef } from "react";
import data from "../utils/data";
import dayjs from "dayjs";
import "../styles/ConsumptionForm.scss";
import swap_icon from "../assets/swap.png";

export default function ConsumptionBlockForm({
  editing,
  transaction,
  children,
  formref,
  resize,
}) {
  const didupdate = useRef(false);
  const [isAlternative, setIsAlternative] = useState(
    transaction?.alternative ? true : false
  );

  useEffect(() => {
    if (!resize) return;
    if (didupdate.current) resize();
    else didupdate.current = true;
  }, [resize, isAlternative]);

  if (!formref) return;
  if (!transaction) transaction = {};

  const BasicBlock = () => {
    return (
      <>
        <div className="input-and-swap">
          <input
            type="text"
            name="color"
            list="colors"
            placeholder="color"
            autoComplete="off"
            defaultValue={transaction.color}
            maxLength={100}
          />
          <datalist id="colors">
            {data.colors.map((color, key) => {
              return <option value={color} key={key} />;
            })}
          </datalist>
          <button
            type="button"
            onClick={() => setIsAlternative((state) => !state)}
          >
            <img src={swap_icon} alt="s" />
          </button>
        </div>
        <input
          type="text"
          name="strain"
          list="strains"
          placeholder="strain"
          autoComplete="off"
          defaultValue={transaction.strain}
          maxLength={100}
        />
        <datalist id="strains">
          {data.strains.map((strain, key) => {
            return <option value={strain} key={key} />;
          })}
        </datalist>
      </>
    );
  };

  const AlternativeBlock = () => {
    return (
      <>
        <div className="input-and-swap">
          <input
            type="text"
            name="alternative"
            list="alternatives"
            placeholder="alternative"
            autoComplete="off"
            defaultValue={transaction.alternative}
            maxLength={100}
          />
          <datalist id="alternatives">
            {data.alternatives.map((alternative, key) => {
              return <option value={alternative} key={key} />;
            })}
          </datalist>
          <button
            type="button"
            onClick={() => setIsAlternative((state) => !state)}
          >
            <img src={swap_icon} alt="s" />
          </button>
        </div>
      </>
    );
  };

  return (
    <form
      ref={formref}
      onSubmit={(e) => e.preventDefault()}
      className={
        editing
          ? "consumptionform editing " + transaction.color?.toLowerCase()
          : "consumptionform"
      }
    >
      {isAlternative ? <AlternativeBlock /> : <BasicBlock />}
      <input
        required
        type="text"
        name="amount"
        step="any"
        placeholder="amount"
        defaultValue={transaction.amount}
        maxLength={100}
      />
      {editing ? (
        <input
          required
          type="datetime-local"
          name="timestamp"
          defaultValue={dayjs(transaction.timestamp).format("YYYY-MM-DDTHH:mm")}
          autoComplete="off"
        />
      ) : null}
      <textarea
        name="info"
        placeholder="additional info"
        defaultValue={transaction.info}
        maxLength={500}
      />
      {children}
    </form>
  );
}
