const data = {
  colors: ["Green", "Red", "White"],
  strains: [
    "Maeng Da",
    "Malay",
    "Dragon",
    "Borneo",
    "Bali",
    "Kapuas",
    "Thai",
    "Elephant",
    "Super",
    "Asia",
    "Horn",
    "Hulu",
    "Indo",
    "Jonkong",
    "Betuangie",
    "Kali",
    "Papua",
    "Riau",
    "Sumatra",
    "Sunda",
    "Vientam",
  ],
  alternatives: ["Javanica", "Hirsuta", "Guarana", "Matcha"],
};

export default data;
