import "./styles/main.scss";
import React, { useRef, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Layout from "./components/Layout";
import LocalDatabase, { LoadDatabase } from "./utils/idb";
import { updateActiveUser, getActiveUser, getToken } from "./utils/util";
import {
  sortTransactions,
  setTransactions,
  loadTransactions,
  loadShortData,
} from "./utils/transactions";
import { loadData as loadSocialData } from "./utils/data.social";
import { LoginUser } from "./utils/login";
import { SetPopup } from "./utils/popup";

function PURGE() {
  window.indexedDB
    .databases()
    .then((r) => {
      for (var i = 0; i < r.length; i++)
        window.indexedDB.deleteDatabase(r[i].name);
    })
    .then(() => {
      SetPopup({ name: "PURGE", content: "All data gone" });
    });
}
//PURGE();

export const App = () => {
  const [loaded, setLoaded] = useState(false);

  const loginInput = useRef();
  const passwordInput = useRef();
  const errorLabel = useRef();

  useEffect(() => {
    updateActiveUser().then(() => {
      const activeUser = getActiveUser();
      if (activeUser) {
        if (loginInput.current) loginInput.current.value = activeUser.value;
      }
    });
  }, []);

  async function LoadApp() {
    const result = await LoginUser(
      loginInput.current.value,
      passwordInput.current.value
    );
    if (result.error) {
      errorLabel.current.innerText = "Something went wrong!";
      return;
    }
    if (result.registered) {
      errorLabel.current.innerText = "You are now registered, tap 'Enter'";
      return;
    }
    if (result.login) {
      // updating db entry: activeUser
      if (!(await getActiveUser())) {
        await LocalDatabase("add", "global", {
          key: "activeUser",
          value: loginInput.current.value,
        });
      } else {
        await LocalDatabase(
          "set",
          "global",
          { key: "activeUser" },
          { key: "activeUser", value: loginInput.current.value }
        );
      }
      // updating db entry: token
      if (!(await getToken())) {
        await LocalDatabase("add", "global", {
          key: "token",
          value: result.token,
        });
      } else {
        await LocalDatabase(
          "set",
          "global",
          { key: "token" },
          { key: "token", value: result.token }
        );
      }
    }
    updateActiveUser();
    LoadDatabase(loginInput.current.value);

    async function load() {
      const transactions = await loadTransactions();
      if (transactions) {
        await loadSocialData();
        await loadShortData();
        setTransactions(sortTransactions(transactions));
        setLoaded(true);
      }
    }
    load();
  }

  // APP
  if (loaded) {
    return (
      <div className="App">
        <Layout>
          <Outlet />
        </Layout>
      </div>
    );
  }

  // LOGIN PAGE
  return (
    <div className="App">
      <div className="name-div">
        <h1>
          Welcome to <span>Kratomisti</span>
        </h1>
        <h2>
          Track the <span>green leaf</span>
        </h2>
      </div>

      <div className="login-div">
        <form onSubmit={(e) => e.preventDefault()} autoComplete="on">
          <label ref={errorLabel}></label>
          <input
            type="text"
            name="username"
            id="username"
            ref={loginInput}
            placeholder="username"
            autoComplete="off"
          />
          <input
            type="password"
            name="password"
            id="password"
            ref={passwordInput}
            placeholder="password"
            autoComplete="off"
          />
          <div className="button-div">
            <button
              type="submit"
              onClick={async () => {
                try {
                  await LoadApp();
                } catch (err) {
                  SetPopup({
                    name: "App loading error",
                    content: `${err.name}: ${err.message}`,
                  });
                }
              }}
            >
              Enter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default App;
