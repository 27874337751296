import React, { useState } from "react";
import Profiles from "../Profiles";
import Feed from "../Feed";
import Chat from "../Chat";
import "../../styles/social.scss";

export default function Social() {
  const [screen, setScreen] = useState("profiles");

  function is(t) {
    if (t === screen) return "selected";
  }

  return (
    <div className="social">
      <div className="nav">
        <div className={is("chat")} onClick={() => setScreen("chat")}>
          Chat
        </div>
        <div className={is("feed")} onClick={() => setScreen("feed")}>
          Feed
        </div>
        <div className={is("profiles")} onClick={() => setScreen("profiles")}>
          Profiles
        </div>
      </div>
      {screen === "chat" ? <Chat /> : <></>}
      {screen === "feed" ? <Feed /> : <></>}
      {screen === "profiles" ? <Profiles /> : <></>}
    </div>
  );
}
