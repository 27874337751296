import { useRef } from "react";
import { getTransactions, refreshTransactions } from "../utils/transactions";
import { getServerIP, getToken } from "../utils/util";
import { SetPopup } from "../utils/popup";
import ConsumptionForm from "./ConsumptionForm";

const SERVER_IP = getServerIP();

export default function ConsumptionBlockEditing({
  transaction,
  setEditState,
  setTransactionsState,
  resize,
}) {
  const form = useRef();

  async function update() {
    if (!form.current.amount?.value || !form.current.timestamp?.value) return;

    let newTransaction = JSON.parse(JSON.stringify(transaction));
    newTransaction.alternative = form.current.alternative?.value;
    newTransaction.color = form.current.color?.value;
    newTransaction.strain = form.current.strain?.value;
    newTransaction.amount = form.current.amount?.value;
    newTransaction.info = form.current.info?.value;
    newTransaction.timestamp = new Date(
      form.current.timestamp?.value
    ).getTime();

    const token = await getToken();

    await fetch(`${SERVER_IP}/api/updatetransaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          token: token.value,
          transaction: transaction,
          new: newTransaction,
        },
      ]),
    }).then(async (res) => {
      await res.json().then(async function (res) {
        if (res.status === "error") {
          SetPopup({ name: res.status, content: "Could not update" });
        }
      });
    });

    await refreshTransactions();
    setTransactionsState(getTransactions());
    setEditState(false);
  }

  function cancel() {
    setEditState(false);
  }

  return (
    <ConsumptionForm
      formref={form}
      editing={true}
      transaction={transaction}
      resize={resize}
    >
      <div className="formbuttons">
        <button
          type="button"
          onClick={() => cancel()}
          className={transaction.color?.toLowerCase()}
        >
          <p>cancel</p>
        </button>
        <button
          type="submit"
          onClick={async () => {
            try {
              await update();
            } catch (err) {
              SetPopup({
                name: "Update error",
                content: `${err.name}: ${err.message}`,
              });
            }
          }}
          className={transaction.color?.toLowerCase()}
        >
          update
        </button>
      </div>
    </ConsumptionForm>
  );
}
