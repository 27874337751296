import "../styles/ConsumptionBlock.scss";
import edit_icon from "../assets/edit.png";
import remove_icon from "../assets/remove.png";
import { useEffect, useState } from "react";
import { getServerIP, getToken } from "../utils/util";
import { refreshTransactions, getTransactions } from "../utils/transactions";
import ConsumptionEditing from "./ConsumptionEditing";
import { SetPopup } from "../utils/popup";
import { useRef } from "react";

const SERVER_IP = getServerIP();

function throttle(cb, delay) {
  let shouldWait = false;
  let waitingArgs;
  const timeoutFunc = () => {
    if (waitingArgs == null) {
      shouldWait = false;
    } else {
      cb(...waitingArgs);
      waitingArgs = null;
      setTimeout(timeoutFunc, delay);
    }
  };

  return (...args) => {
    if (shouldWait) {
      waitingArgs = args;
      return;
    }

    cb(...args);
    shouldWait = true;

    setTimeout(timeoutFunc, delay);
  };
}

export default function ConsumptionBlock({
  transaction,
  setTransactionsState,
}) {
  const [editing, setEditState] = useState(false);
  const block = useRef(null);

  const throttleHandleTransition = throttle(() => {
    let previous = block.current.style.height;
    block.current.style.transition = "none";
    block.current.style.height = "";
    let next = block.current.clientHeight + "px";
    block.current.style.height = previous;
    block.current.style.transition =
      "height 200ms cubic-bezier(0.85, 0.25, 0.6, 0.6)";

    // needed for transition to take effect & 50ms magically fixes first transition
    setTimeout(function () {
      block.current.style.height = next;
    }, 50);
  }, 100);

  useEffect(() => {
    return (_) => {
      setEditState(false); // close after children nodes are moved
    };
  }, [transaction]);

  useEffect(() => {
    throttleHandleTransition();

    window.addEventListener("resize", throttleHandleTransition);

    return (_) => {
      window.removeEventListener("resize", throttleHandleTransition);
    };
  }, [editing, throttleHandleTransition]);

  function edit() {
    setEditState(true);
  }

  async function remove() {
    const token = await getToken();
    await fetch(`${SERVER_IP}/api/removetransaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          token: token.value,
          transaction: transaction,
        },
      ]),
    }).then(async function (res) {
      await res.json().then(async function (data) {
        if (data.status === "error") {
          SetPopup({ name: data.status, content: "Could not remove" });
        }
      });
    });
    await refreshTransactions();
    setTransactionsState(getTransactions());
  }

  const ConsumptionBlockBasic = () => (
    <div className="basic">
      <div className="data">
        <div className="left">
          <h1>
            {transaction.alternative ? (
              <span>{transaction.alternative}</span>
            ) : (
              <>
                <span>{transaction.color}</span>
                <span>- {transaction.strain}</span>
              </>
            )}
          </h1>
          <p>{new Date(transaction.timestamp).toLocaleString()}</p>
        </div>
        <div className="right">
          <p>{transaction.amount}g</p>
        </div>
      </div>
      <div className="buttons">
        <button
          type="button"
          onClick={async () => {
            try {
              await remove();
            } catch (err) {
              SetPopup({
                name: "Remove error",
                content: `${err.name}: ${err.message}`,
              });
            }
          }}
          className={transaction.color?.toLowerCase()}
        >
          <img src={remove_icon} alt="x" />
        </button>
        <button
          type="button"
          onClick={() => edit()}
          className={transaction.color?.toLowerCase()}
        >
          <img src={edit_icon} alt="e" />
        </button>
      </div>
      <div className="info">
        <p>{transaction.info}</p>
      </div>
    </div>
  );

  return (
    <div
      ref={block}
      className={
        (editing ? "resized" : "") +
        " block " +
        transaction.color?.toLowerCase()
      }
    >
      {editing ? (
        <ConsumptionEditing
          resize={throttleHandleTransition}
          setEditState={setEditState}
          transaction={transaction}
          setTransactionsState={setTransactionsState}
        />
      ) : (
        <ConsumptionBlockBasic />
      )}
    </div>
  );
}
