import { addComment } from "./data.comments";
import { sortTransactions } from "./transactions";
import { getServerIP } from "./util";

const SERVER_IP = getServerIP();

let data = {};
let loaded = 0;
const TO_LOAD = 10;

export const getData = () => data;

export function setData(d) {
  data = d;
}

export async function loadFeed() {
  if (data.feed.length && !loaded) {
    loaded += data.feed.length;
  }
  if (loaded % TO_LOAD != 0) return;
  const fetchedData = await fetch(
    `${SERVER_IP}/api/getfeed?loaded=${loaded}&toload=${TO_LOAD}`
  ).then((resp) =>
    resp.json().then((res) => {
      console.log(res);
      return res;
    })
  );
  if (!fetchedData.status === "error") return;
  if (!fetchedData.length) return;
  loaded += fetchedData.length;

  let tempData = {
    profiles: getData().profiles,
    feed: sortTransactions([...data.feed, ...fetchedData]),
  };
  addFeedCommentsToCache(fetchedData);
  setData(tempData);
  return fetchedData.length;
}

export async function loadData() {
  loaded = 0;
  const fetchedUsers = await fetch(`${SERVER_IP}/api/getusers`).then((resp) =>
    resp.json().then((b) => {
      return b;
    })
  );

  let tempProfiles = [];
  for (const user of fetchedUsers) {
    const fetchedData = await fetch(
      `${SERVER_IP}/api/getusertransactions?username=${user}&short=true`
    ).then((resp) =>
      resp.json().then((res) => {
        return res;
      })
    );
    tempProfiles.push(fetchedData);
  }

  const fetchedData = await fetch(
    `${SERVER_IP}/api/getfeed?loaded=${loaded}&toload=${TO_LOAD}`
  ).then((resp) =>
    resp.json().then((res) => {
      return res;
    })
  );
  let tempFeed = sortTransactions(fetchedData);

  let tempData = {
    profiles: tempProfiles,
    feed: tempFeed,
  };
  addFeedCommentsToCache(tempFeed);
  setData(tempData);
}

function addFeedCommentsToCache(feed) {
  for (const txn of feed) {
    for (const comment of txn.comments) {
      addComment(comment);
    }
  }
}
