import React, { useState, useEffect } from "react";
import ConsumptionBlockSocial from "./ConsumptionBlock.social";
import DateSeparator from "./DateSeparator";
import "../styles/profilepage.scss";
import { getServerIP } from "../utils/util";
import { sortTransactions } from "../utils/transactions";
import { SetPopup } from "../utils/popup";

const SERVER_IP = getServerIP();

let previous = 0;
const setPrevious = (num) => (previous = num);
const getPrevious = () => previous;

const GroupBlock = ({ statgroup }) => (
  <div className="group">
    <h2>{statgroup.name}</h2>
    {statgroup.stats?.map((stat, key) => {
      return (
        <div className="stat" key={key}>
          <div className="name">{stat.name}</div>
          <div className="value">{stat.value}</div>
        </div>
      );
    })}
  </div>
);

export default function ProfilePage({ user, openProfile }) {
  const [data, setData] = useState([]);
  const [viewing, setViewing] = useState("usage");
  const [statgroups, setStatgroups] = useState([]);

  function is(t) {
    if (t === viewing) return "selected";
  }

  // incremental transaction load, fully renders from the top
  previous = 0;

  useEffect(() => {
    if (viewing === "statistics" && !statgroups.length && data.length) {
      async function calc() {
        await fetch(
          `${SERVER_IP}/api/calculatestatistics?username=${user}`
        ).then((resp) =>
          resp.json().then((res) => {
            if (res.status === "error") {
              SetPopup({
                name: "API Fetch Error",
                content: `/api/calculatestatistics?username=${user}`,
              });
              return;
            }
            if (!res.length) return;
            setStatgroups(res);
          })
        );
      }
      calc();
    }
  }, [viewing]);

  useEffect(() => {
    async function scrollListener(e) {
      if (viewing === "statistics") return;
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      if (scrollTop + offsetHeight >= scrollHeight) {
        document
          .getElementsByClassName("container")[0]
          .removeEventListener("scroll", scrollListener);

        await fetch(
          `${SERVER_IP}/api/getusertransactions?username=${user}&loaded=${data.length}&toload=15`
        ).then((resp) =>
          resp.json().then((res) => {
            if (res.status === "error") {
              SetPopup({
                name: "API Fetch Error",
                content: `/api/getusertransactions?username=${user}&loaded=${data.length}`,
              });
              return;
            }
            if (!res.transactions.length) return;
            setData((data) => sortTransactions([...data, ...res.transactions]));
          })
        );
      }
    }

    document
      .getElementsByClassName("container")[0]
      .addEventListener("scroll", scrollListener);

    return () => {
      document
        .getElementsByClassName("container")[0]
        .removeEventListener("scroll", scrollListener);
    };
  }, [data, viewing]);

  useEffect(() => {
    fetch(
      `${SERVER_IP}/api/getusertransactions?username=${user}&loaded=${data.length}&toload=15`
    ).then((resp) =>
      resp.json().then((res) => {
        setData((data) => sortTransactions([...data, ...res.transactions]));
      })
    );
  }, [user]);

  const UsageView = () => (
    <div className="transactions">
      {data?.map((txn, key) => {
        return (
          <React.Fragment key={key}>
            <DateSeparator
              timestamp={txn.timestamp}
              getPrevious={getPrevious}
              setPrevious={setPrevious}
            />
            <ConsumptionBlockSocial transaction={txn} />
          </React.Fragment>
        );
      })}
    </div>
  );

  const StatsView = () => (
    <div className="statistics">
      {statgroups?.map((statgroup, key) => {
        return <GroupBlock statgroup={statgroup} key={key} />;
      })}
    </div>
  );

  return (
    <div className="profilepage">
      <div className="head">
        <div className="back" onClick={() => openProfile(null)}>
          <p>{"< back"}</p>
        </div>
        <h1>{user}</h1>
        <div className="nav">
          <div className={is("usage")} onClick={() => setViewing("usage")}>
            Usage
          </div>
          <div
            className={is("statistics")}
            onClick={() => setViewing("statistics")}
          >
            Statistics
          </div>
        </div>
      </div>
      {viewing === "usage" ? <UsageView /> : <></>}
      {viewing === "statistics" ? <StatsView /> : <></>}
    </div>
  );
}
