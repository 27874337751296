import Localbase from "localbase";
let g_localdb = new Localbase("global");
let localdb;

export function LoadDatabase(user) {
  localdb = new Localbase(user);
}

/* UNDEFINED RESULT FIX */
async function validate(f) {
  const res = await f();
  if (res === undefined) {
    if (localdb) await localdb.collection().doc().get();
    else await g_localdb.collection().doc().get();
    return await f();
  }
  return res;
}

/* PURE DB USAGE */
export async function CommandPureDB(callback) {
  if (typeof callback !== "function") return;
  await callback(localdb);
}

/*WARNING: WHOLE DATABASE*/
async function _removeDatabase(db) {
  LoadDatabase(db);
  await localdb.delete();
  return;
}

/* WHOLE COLLECTIONS */
async function _getCollection(collection) {
  if (collection === "global") {
    const res = await validate(() => g_localdb.collection(collection).get());
    return res;
  }
  const res = await validate(() => localdb.collection(collection).get());
  return res;
}

async function _removeCollection(collection) {
  if (collection === "global") {
    await g_localdb.collection(collection).delete();
    return;
  }
  await localdb.collection(collection).delete();
  return;
}

/* DOCS INSIDE COLLECTIONS*/

async function _addToCollection(collection, doc) {
  if (collection === "global") {
    await g_localdb.collection(collection).add(doc);
    return;
  }
  await localdb.collection(collection).add(doc);
  return;
}

async function _getFromCollection(collection, doc, extra) {
  if (collection === "global") {
    const res = await validate(() =>
      g_localdb.collection(collection).doc(doc).get()
    );
    return res;
  }
  if (extra?._initDB) {
    LoadDatabase(doc.owner);
  }
  const res = await validate(() =>
    localdb.collection(collection).doc(doc).get()
  );
  return res;
}

async function _removeFromCollection(collection, doc) {
  if (collection === "global") {
    await g_localdb.collection(collection).doc(doc).delete();
    return;
  }
  try {
    await localdb.collection(collection).doc(doc).delete();
  } catch (err) {
    console.error(err);
  }
  return;
}

async function _setInCollection(collection, doc, newDoc) {
  if (collection === "global") {
    await g_localdb.collection(collection).doc(doc).set(newDoc);
    return;
  }
  await localdb.collection(collection).doc(doc).set(newDoc);
  return;
}

async function _updateInCollection(collection, doc, newDoc) {
  if (collection === "global") {
    await g_localdb.collection(collection).doc(doc).update(newDoc);
    return;
  }
  await localdb.collection(collection).doc(doc).update(newDoc);
  return;
}

export default async function LocalDatabase(
  method,
  collection,
  doc = "",
  newDoc = ""
) {
  if (
    ![
      "add",
      "remove",
      "get",
      "set",
      "update",
      "getcol",
      "removecol",
      "removedatabase",
    ].includes(method)
  )
    return false;

  let res;
  switch (method) {
    /*working with documents inside collections*/
    case "add":
      await _addToCollection(collection, doc);
      return true;
    case "get":
      res = await _getFromCollection(collection, doc, newDoc);
      return res;
    case "remove":
      await _removeFromCollection(collection, doc);
      return true;
    case "set":
      await _setInCollection(collection, doc, newDoc);
      return true;
    case "update":
      await _updateInCollection(collection, doc, newDoc);
      return true;
    /*working with whole collections*/
    case "getcol":
      res = await _getCollection(collection, doc);
      return res;
    case "removecol":
      await _removeCollection(collection);
      return true;
    /*working with whole DATABASE*/
    case "removedatabase":
      await _removeDatabase(collection);
      return true;
    default:
      return;
  }
}
