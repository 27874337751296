import { getServerIP } from "./util";

const SERVER_IP = getServerIP();

let data = {};

export const getComments = async (txnid) => {
  if (data[txnid]) return data[txnid];

  await loadComments(txnid);

  return data[txnid];
};

export function addComment(comment) {
  if (!comment || !comment.txnid) return;

  let comments = data[comment.txnid] || [];
  comments.push(comment);
  data[comment.txnid] = comments;
}

export async function loadComments(txnid) {
  if (!txnid) return;
  if (data[txnid]) return;
  const fetchedComments = await fetch(
    `${SERVER_IP}/api/getcomments?txnid=${txnid}`
  ).then((resp) =>
    resp.json().then((b) => {
      return b;
    })
  );
  data[txnid] = fetchedComments || [];
}
