import React, { useRef } from "react";
import LocalDatabase from "../../utils/idb";
import "../../styles/add.scss";
import { refreshTransactions } from "../../utils/transactions";
import { getServerIP, getActiveUser } from "../../utils/util";
import { SetPopup } from "../../utils/popup";
import ConsumptionForm from "../ConsumptionForm";

const SERVER_IP = getServerIP();

export default function Add() {
  const form = useRef();

  async function submitData() {
    if (!form.current.amount.value) return;
    const username = (await getActiveUser()).value;
    const token = await LocalDatabase("get", "global", { key: "token" });
    const data = {
      username: username,
      alternative: form.current.alternative?.value,
      color: form.current.color?.value,
      strain: form.current.strain?.value,
      amount: form.current.amount?.value,
      info: form.current.info?.value,
      timestamp: new Date().getTime(),
    };
    for (const input of form.current) {
      input.value = "";
    }
    await fetch(`${SERVER_IP}/api/addtransaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          token: token.value,
          transaction: data,
        },
      ]),
    }).then(async function (res) {
      await res.json().then(async function (res) {
        if (res.status === "error") {
          SetPopup({ name: res.status, content: "Could not add" });
        }
      });
    });
    await refreshTransactions();
  }

  return (
    <div className="add">
      <h2>
        <i>
          "There are good days and bad days. There are days when you consume{" "}
          <span className="leaf">leaf</span> and days when there is no{" "}
          <span className="leaf">leaf</span> left."
        </i>{" "}
        - Filip
      </h2>
      <ConsumptionForm formref={form}>
        <button
          type="submit"
          onClick={async () => {
            try {
              await submitData();
            } catch (err) {
              SetPopup({
                name: "Data submit error",
                content: `${err.name}: ${err.message}`,
              });
            }
          }}
        >
          Submit
        </button>
      </ConsumptionForm>
    </div>
  );
}
