export async function defaultTryCatch(fn, name) {
  try {
    await fn();
  } catch (err) {
    SetPopup({
      name,
      content: `${err.name}: ${err.message}`,
    });
  }
}

export function SetPopup({ name, content }) {
  const popup = document.getElementById("popupcontainer");
  popup.style.opacity = "1";
  popup.style.visibility = "visible";
  popup.style.display = "flex";

  const popupname = document.getElementById("popupname");
  const popupcontent = document.getElementById("popupcontent");
  popupname.innerText = name;
  popupcontent.innerText = content;
}

export function initPopup() {
  const popup = document.createElement("div");
  popup.classList.add("popup-container");
  popup.id = "popupcontainer";
  popup.style.position = "absolute";
  popup.style.top = "0";

  popup.style.opacity = "0";
  popup.style.visibility = "hidden";
  popup.style.display = "none";

  popup.insertAdjacentHTML(
    "beforeend",
    '<div class="popup-container-bg"></div>' +
      '<div class="popup-block">' +
      '<h1 id="popupname" class="name"></h1>' +
      '<p id="popupcontent" class="content"></p>' +
      '<button id="popupclose" type="button" class="close">OK</button>' +
      "</div>"
  );

  document.body.appendChild(popup);

  document.getElementById("popupclose").addEventListener("click", closePopup);
}

function closePopup() {
  const popup = document.getElementById("popupcontainer");
  popup.style.opacity = "0";
  popup.style.visibility = "hidden";
  popup.style.display = "none";
}
