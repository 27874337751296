import { getActiveUser, getServerIP } from "./util";
import { loadData as loadSocialData } from "./data.social";
import { SetPopup } from "./popup";

const SERVER_IP = getServerIP();

let shortData = {};
let transactions = [];
let loaded = 0;
const TO_LOAD = 15;

export const getTransactions = () => transactions;
export const getShortData = () => shortData;

export function setTransactions(txns) {
  transactions = txns;
}

export async function loadShortData() {
  const ACTIVE_USER = (await getActiveUser())?.value;
  const res = await fetch(
    `${SERVER_IP}/api/getusertransactions?username=${ACTIVE_USER}&short=true`
  ).then(
    async (res) =>
      await res.json().then((res) => {
        return res;
      })
  );
  shortData = res;
  return;
}

export async function loadTransactions(wasloaded = loaded) {
  const ACTIVE_USER = (await getActiveUser())?.value;
  const res = await fetch(
    `${SERVER_IP}/api/getusertransactions?username=${ACTIVE_USER}&loaded=${wasloaded}&toload=${TO_LOAD}`
  ).then(
    async (res) =>
      await res.json().then((res) => {
        if (res.status === "error") {
          SetPopup({
            name: "API Fetch Error",
            content: `/api/getusertransactions?username=${ACTIVE_USER}&loaded=${wasloaded}&toload=${TO_LOAD}`,
          });
          return;
        }
        loaded += res.transactions.length;
        return res.transactions;
      })
  );
  return res;
}

async function loopedLoad() {
  const wasloaded = loaded;
  let incLoaded = 0;

  loaded = 0;
  setTransactions([]);

  while (incLoaded < wasloaded) {
    const result = await loadTransactions(incLoaded);
    if (!result.length) break;

    incLoaded += result.length;
    setTransactions([...getTransactions(), ...result]);

    if (result.length < TO_LOAD) break;
  }
  return getTransactions();
}

export async function refreshTransactions() {
  if (loaded === 0) {
    loaded = 1;
  }
  const accumulatedFetchedTxns = await loopedLoad();

  await loadShortData();
  setTransactions(sortTransactions(accumulatedFetchedTxns));
  loadSocialData();
}

export function sortTransactions(txn) {
  txn?.sort(function (a, b) {
    if (a.timestamp > b.timestamp) return -1;
    if (a.timestamp < b.timestamp) return 1;
    return 0;
  });
  return txn;
}
