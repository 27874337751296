import LocalDatabase from "./idb";

const config = require("../config.json");
const SERVER_IP = config.SERVER_IP;

let activeUser = "";

export const limitDecimal = (amount, decimal) =>
  parseFloat(Math.round(amount * decimal) / decimal);

export const getServerIP = () => SERVER_IP;

// TODO: change all the activeUser.value code to just activeUser, and add it below
export const getActiveUser = () => activeUser;

export async function updateActiveUser() {
  const result = await LocalDatabase("get", "global", {
    key: "activeUser",
  });
  activeUser = result;
}

export async function getToken() {
  return await LocalDatabase("get", "global", { key: "token" });
}
