import { getServerIP, getToken } from "./util";
import { SetPopup } from "./popup";

const md5 = require("md5");
const SERVER_IP = getServerIP();

export async function LoginUser(username, password) {
  const token = (await getToken())?.value;
  let response;

  try {
    //login
    if (!username || !(password || token)) return { error: true };
    await fetch(`${SERVER_IP}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          username: username,
          hash: password ? md5(password) : null,
          token: token,
        },
      ]),
    }).then(async function (res) {
      await res.json().then(function (data) {
        if (data.status === "ok") response = { login: true, token: data.token };
        else response = { error: true };
      });
    });
    if (response.login) return response;

    //register if login failed
    if (!password) return { error: true };
    const hash = md5(password);
    await fetch(`${SERVER_IP}/api/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([{ username: username, hash: hash }]),
    }).then(async function (res) {
      await res.json().then(function (data) {
        if (data.status === "success") response = { registered: true };
        else response = { error: true };
      });
    });

    return response;
  } catch (err) {
    SetPopup({ name: "Login failed", content: `${err.name}: ${err.message}` });
    return { error: true };
  }
}
