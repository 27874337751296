import { SetPopup } from "./popup";
import { sortTransactions } from "./transactions";
import { getServerIP } from "./util";

const SERVER_IP = getServerIP();

let data = [];
let timestamp = {
  oldest: new Date().getTime(),
  latest: 0,
};

export const getChat = () => data;
export const setChat = (chat) => (data = chat);
export const sortChat = () => setChat(sortTransactions(data));

export const timestampOldest = () => timestamp.oldest;
export const timestampLatest = () => timestamp.latest;

export const timestampUpdate = () => {
  if (!data.length) return;

  timestamp.oldest = data[data.length - 1].timestamp;
  timestamp.latest = data[0].timestamp;
};

export function addMessages(messages) {
  if (!messages || !messages.length) return;

  // need intermediate variable or useState gets broken
  let chat = [...data];
  chat.push(...messages);
  setChat(chat);
  sortChat();
  timestampUpdate();
}

export async function loadChat() {
  try {
    await fetch(
      `${SERVER_IP}/api/getchat?timestamp=${timestampOldest()}&toload=15`
    ).then((resp) =>
      resp.json().then((res) => {
        if (res.status === "error") {
          SetPopup({
            name: "API Fetch Error",
            content: `/api/getchat?timestamp=${timestampOldest()}&toload=15`,
          });
          return;
        }
        if (!res.messages.length) return;
        addMessages(res.messages);
      })
    );
  } catch (err) {
    SetPopup({
      name: "/api/getchat",
      content: err.message,
    });
  }
}

export async function updateChat() {
  try {
    await fetch(
      `${SERVER_IP}/api/getchat?updating=true&timestamp=${timestampLatest()}&toload=15`
    ).then((resp) =>
      resp.json().then((res) => {
        if (res.status === "error") {
          SetPopup({
            name: "API Fetch Error",
            content: `/api/getchat?updating=true&timestamp=${timestampLatest()}&toload=15`,
          });
          return;
        }
        if (!res.messages.length) return;
        addMessages(res.messages);
      })
    );
  } catch (err) {
    SetPopup({
      name: "/api/getchat",
      content: err.message,
    });
  }
}
