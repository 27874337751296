import "../styles/layout.scss";
import { Link, useLocation } from "react-router-dom";

export default function Layout({ children }) {
  const location = useLocation();
  function is(t) {
    if (t === location.pathname) return "active";
  }
  return (
    <div className="layout">
      <div className="container">{children}</div>
      <nav>
        <Link to="/add">
          <div className={is("/add")}>Add </div>
        </Link>
        <Link to="/home">
          <div className={is("/home") || is("/")}>Home</div>
        </Link>
        <Link to="/social">
          <div className={is("/social")}>Social </div>
        </Link>
      </nav>
    </div>
  );
}
