import React, { useState } from "react";
import { getData } from "../utils/data.social";
import ProfilePage from "./ProfilePage";
import ProfileBlock from "./ProfileBlock";

export default function Profiles() {
  const [openedProfile, openProfile] = useState(false);
  const { profiles: data } = getData();

  if (openedProfile) {
    return <ProfilePage user={openedProfile} openProfile={openProfile} />;
  }

  return (
    <div className="profiles">
      {data?.map((userdata, key) => {
        return (
          <ProfileBlock data={userdata} key={key} openProfile={openProfile} />
        );
      })}
    </div>
  );
}
